@import "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
:root {
  --first-color: #369;
  --first-color-alt: #020202;
  --text-color: #020202;
  --white-color: white;
  --container-color: white;
  --body-font: "Poppins", sans-serif;
  --z-tooltip: 10;
  --z-fixed: 100;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  color: var(--text-color);
  overflow-x: hidden;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

span {
  color: var(--first-color);
}

.error {
  color: red;
}

.main {
  position: relative;
}

.header {
  width: 100vw;
  height: 20vh;
  background: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: 0 0 10px #020202c1;
}

.headings {
  background-color: var(--first-color);
  width: 100%;
  height: 50%;
  margin-left: auto;
  margin-right: auto;
}

.headings-infos {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.headings-infos p {
  text-align: center;
  color: #fff;
  font-family: var(--body-font);
  font-size: 1rem;
}

.social i {
  color: #fff;
  font-size: 1.5rem;
}

.nav-container {
  height: 100%;
  width: 100%;
  font-family: var(--body-font);
  max-width: 1185px;
  background: #fff;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.logo img {
  width: 200px;
}

.nav-container a {
  color: #000;
  font-family: var(--body-font);
  font-weight: 400;
  text-decoration: none;
  transition: all .3s;
}

.nav-container a:hover {
  opacity: .5;
}

.nav-list {
  text-align: center;
  justify-content: center;
  align-items: center;
  list-style: none;
  display: flex;
}

.nav-list li {
  letter-spacing: 3px;
  margin-left: 32px;
}

.mobile-menu {
  cursor: pointer;
  display: none;
}

.mobile-menu div {
  width: 32px;
  height: 2px;
  background: #020202;
  margin: 8px;
  transition: all .3s;
}

.mobile-menu.active .line1 {
  transform: rotate(-45deg)translate(-8px, 8px);
}

.mobile-menu.active .line2 {
  opacity: 0;
}

.mobile-menu.active .line3 {
  transform: rotate(45deg)translate(-5px, -7px);
}

.banner-container {
  height: 80vh;
  width: 100%;
  background-color: #369;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  margin: auto;
  display: flex;
}

.banner-container h1 {
  font-family: var(--body-font);
  color: #fff;
  text-align: center;
  font-size: 4rem;
  font-weight: bolder;
}

.banner-container h2 {
  font-family: var(--body-font);
  color: #fff;
  text-align: center;
  font-size: 3rem;
  font-weight: 100;
}

.banner-container img {
  width: 600px;
  max-width: 100%;
  -webkit-filter: drop-shadow(5px 5px #000) drop-shadow(-1px -1px #000);
  filter: drop-shadow(5px 5px 5px #00000080) drop-shadow(-1px -1px #000);
}

.about {
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  display: flex;
}

.titulos {
  font-family: var(--body-font);
  text-align: center;
  border-bottom: 1px solid #080707;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 3.5rem;
  font-weight: lighter;
}

.sobre-text {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.sobre-text img {
  width: 480px;
  max-width: 100%;
}

.sobre-text p {
  font-family: var(--body-font);
  max-width: 600px;
  text-align: center;
  margin: 30px;
  font-size: clamp(.8em, .8em + 1vw, 1.2em);
  font-weight: 300;
}

.diferenciais-container {
  background-color: var(--first-color);
  color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.nosso-diferencial {
  border-bottom: 1px solid #fff;
}

.diferenciais-container h3 {
  text-align: center;
  width: 100vw;
  margin-top: 50px;
  margin-bottom: 50px;
}

.diferencial-container {
  width: 100%;
  max-width: 1230px;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 0 auto;
  display: flex;
}

.diferencial-titulo {
  font-family: var(--body-font);
  font-size: 1.8rem;
  font-weight: 600;
}

.diferencial-titulo:before {
  content: "";
  color: var(--first-color);
  background-color: #fff;
  margin-right: 10px;
  padding: 8px;
  font-family: "Font Awesome 5 Free";
  font-size: 26px;
  font-weight: 900;
}

.diferencial-titulo--comp:before {
  content: "";
}

.diferencial-titulo--met:before {
  content: "";
}

.diferencial-titulo--prof:before {
  content: "";
}

.diferencial-texto {
  font-family: var(--body-font);
  width: 350px;
  font-size: 18px;
  font-weight: 300;
}

.services-container {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.services-h1 {
  align-self: center;
  margin-bottom: 0;
  display: inline-block;
}

.services-container p {
  text-align: center;
  font-family: var(--body-font);
  width: 60vw;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: max(18px, 1.1vw);
  font-weight: 300;
}

.services-flex {
  width: 100%;
  max-width: 1200px;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  display: flex;
  position: relative;
  overflow-x: auto;
}

.services-flex picture {
  background-color: var(--first-color);
  cursor: pointer;
  flex-shrink: 0;
}

.services-flex h1 {
  text-align: center;
  color: #fff;
  background-color: var(--first-color);
  width: 100%;
  align-self: center;
  font-size: 1.2em;
}

.services-flex img {
  min-width: 360px;
  min-height: 360px;
  width: 356px;
}

.galeria {
  background-color: var(--first-color);
  padding-bottom: 50px;
}

.gallery-text {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.gallery-text h1 {
  color: #fff;
  border-bottom: 1px solid #fff;
}

.gallery-text p {
  text-align: center;
  font-family: var(--body-font);
  color: #fff;
  margin-bottom: 50px;
  font-size: max(18px, 1.1vw);
  font-weight: 300;
}

.footer-infos {
  width: 100vw;
  background-color: #fff;
  flex-direction: column;
  display: flex;
}

.footer-text {
  width: 100%;
  max-width: 1175px;
  text-align: center;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.footer-text h2, .footer-text h3 {
  font-weight: 300;
  font-family: var(--body-font);
  padding: 2px;
}

.footer-btn {
  width: 180px;
  height: 50px;
  color: #fff;
  background-color: var(--first-color);
  font-family: var(--body-font);
  cursor: pointer;
  border: none;
  border-radius: 10px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

.footer-btn:hover {
  color: #000;
  background-color: #add8e6;
}

.footer-btn:active {
  transform: translate(5px, 5px);
}

.localizacao {
  max-width: 100%;
  max-height: 100%;
  width: 1920px;
  height: 400px;
}

.footer-rights {
  height: 5%;
  text-align: center;
  background-color: #020202;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.footer-rights--text {
  color: #fff;
  font-family: var(--body-font);
  font-size: 1.1em;
  font-weight: 600;
}

.fa-copyright {
  color: #fff;
  margin-right: 5px;
}

.icon-container {
  position: relative;
}

.fixed_icon {
  color: #fff;
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
  z-index: 10;
  background-color: #228b22;
  border-radius: .5rem;
  padding: .45rem;
  font-size: 3rem;
  transition: all .4s;
  animation: zap .5s ease-in infinite alternate;
  display: inline-flex;
  position: fixed;
  bottom: 1rem;
  left: 1rem;
}

.fixed_icon:hover {
  transform: translateY(-.25rem);
}

@media (max-width: 840px) {
  .banner-container h2 {
    font-size: 2rem;
  }

  .sobre-text p {
    width: 400px;
  }

  .nosso-diferencial {
    font-size: 2.5rem;
  }

  .diferencial-cards, .diferenciais-container h3 {
    margin-bottom: 50px;
  }

  .services-flex:after {
    content: "";
    color: #fff;
    background-color: var(--first-color);
    cursor: pointer;
    opacity: .5;
    margin-right: 10px;
    padding: 8px;
    font-family: "Font Awesome 5 Free";
    font-size: 26px;
    font-weight: 900;
    animation: arrow .5s ease-in infinite alternate;
    position: absolute;
    top: 10px;
    right: 250px;
  }

  @keyframes arrow {
    from {
      right: 5.2rem;
    }

    to {
      right: 3.2rem;
    }
  }
}

@keyframes zap {
  from {
    bottom: 3.8rem;
  }

  to {
    bottom: 3rem;
  }
}

@media (max-width: 1200px) {
  .services-flex {
    width: 100vw;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    overflow-x: auto;
  }

  .services-flex picture {
    margin-left: 1.4rem;
  }

  picture:last-child {
    margin-right: 1.4rem;
  }
}

@media (max-width: 439px) {
  .banner-container h1 {
    font-size: 2rem;
  }

  .footer-text, .sobre-text {
    width: 80vw;
  }
}

@media (max-width: 999px) {
  body {
    overflow-x: hidden;
  }

  .nav-list {
    width: 100vw;
    height: 20vh;
    z-index: 3;
    background: #fff;
    border: 1px solid #00000047;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    transition: transform .3s ease-in;
    position: absolute;
    top: 20vh;
    right: 0;
    transform: translateX(100%);
  }

  .nav-list li {
    opacity: 0;
    border: 1px solid #00000047;
    border-radius: 4px;
    margin: 8px auto;
    padding: 4px;
  }

  .mobile-menu {
    display: block;
  }

  .form-adress, .form-select {
    flex-direction: column;
  }

  .form-adress > input {
    width: 100%;
  }
}

.nav-list.active {
  transform: translateX(0);
}

@keyframes navLinkFade {
  from {
    opacity: 0;
    transform: translateX(50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

input, select, textarea {
  border-radius: 5px;
}

.container {
  background-color: var(--first-color);
  margin: auto;
  padding: 12px;
}

.header-form {
  width: 90%;
  color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 8px auto;
  display: flex;
}

.header-form > a > img {
  border-radius: 16px;
  padding: 12px;
}

.form-matricula {
  max-width: 90%;
  background-color: #fff;
  border: 1px solid #6666662b;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin: auto;
  padding: 24px;
  display: flex;
}

.form-text-input {
  width: 90%;
  margin: auto;
  padding: 8px;
}

.form-adress {
  width: 90%;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin: 8px auto;
  display: flex;
}

.form-select {
  width: 90%;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.form-select > div > select {
  width: 90%;
  margin: 12px auto;
  padding: 8px;
}

.form-footer {
  color: #fff;
  background-color: #369;
  border: 1px solid #666;
  border-radius: 8px;
  margin: auto;
  padding: 12px;
  font-size: clamp(.8em, .8em + 1vw, 1.2em);
}

.form-button {
  min-width: 250px;
  color: #191818;
  background-color: #40e840;
  margin: 8px auto;
  padding: 8px;
}

.form-text-area {
  width: 90%;
  margin: auto;
  padding: 8px;
}

/*# sourceMappingURL=form-maticula.867a084f.css.map */
