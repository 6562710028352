/*=============== GOOGLE FONTS ===============*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/*=============== VARIABLES CSS ===============*/
:root {
   /*========== Colors ==========*/
  --first-color:#336699 ;
  --first-color-alt: #020202;
  --text-color: #020202;
  --white-color: white;
  --container-color: white;

  /*========== Font and typography ==========*/
  /*.5rem = 8px | 1rem = 16px ...*/
  --body-font: 'Poppins', sans-serif;;


  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}

/*=============== BASE ===============*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  color: var(--text-color);
  overflow-x: hidden;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}


span{
  color:var(--first-color);
}

.error{
  color: red;
}

.main {
  position: relative;
}

/*=============== HEADER - MENUS ===============*/
.header{
  width: 100vw;
  height: 20vh;
  box-shadow: 0px 0px 10px #020202c1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  
}

.headings{
  background-color: var(--first-color);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 50%;
}

.headings-infos{ 
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}

.headings-infos p{
  text-align: center;
  color: white;
  font-family: var(--body-font);
  font-size:1rem;
}

.social i{
  color: white;
  font-size:1.5rem;
}

.nav-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: var(--body-font);
  background: white;
  max-width: 1185px;
  margin: 0 auto;
}

.logo img{
  width: 200px;
}

.nav-container a{
  color:black;
  text-decoration: none;
  transition: 0.3s;
  font-family: var(--body-font);
  font-weight: 400;
}

.nav-container a:hover {
  opacity: 0.5;
}

.nav-list {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.nav-list li {
  letter-spacing: 3px;
  margin-left: 32px;
}

.mobile-menu {
  display: none;
  cursor: pointer;
}

.mobile-menu div {
  width: 32px;
  height: 2px;
  background: #020202;
  margin: 8px;
  transition: 0.3s;
}

.mobile-menu.active .line1 {
  transform: rotate(-45deg) translate(-8px, 8px);
}

.mobile-menu.active .line2 {
  opacity: 0;
}

.mobile-menu.active .line3 {
  transform: rotate(45deg) translate(-5px, -7px);
}

/*=============== BANNER ===============*/

.banner-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: wrap;
  background-color: #336699;
  height: 80vh;
  width: 100%;
  margin: auto;
  /* background-image: url('../img/banner-mat-2023_0.webp');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center; */
}

.banner-container h1{
  font-family: var(--body-font);
  color: white;
  font-weight: bolder;
  text-align: center;
  font-size: 4rem;
  
}

.banner-container h2{
  font-family: var(--body-font);
  color: white;
  font-weight: bolder;
  text-align: center;
  font-size: 3rem;
  font-weight: 100;
}

 .banner-container img {
  width: 600px;
  max-width: 100%;
  -webkit-filter: drop-shadow(5px 5px 0 black)
                  drop-shadow(-1px -1px 0 black);
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5)) 
          drop-shadow(-1px -1px 0 black);
}


/*=============== SOBRE ===============*/

.about{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between; 
  height: 100%;
  margin-bottom: 50px;
}

.titulos{
  font-family: var(--body-font);
  font-weight: lighter;
  font-size: 3.5rem;
  margin-top: 50px;
  margin-bottom: 50px;
  border-bottom: 1px solid rgb(8, 7, 7);
  text-align: center;
}

.sobre-text{
display: flex;
justify-content: center;
align-items: center;
margin: 0 auto;
flex-direction: column;
}

.sobre-text img{
  width: 480px;
  max-width: 100%;
}


.sobre-text p{
  font-family: var(--body-font);
  font-size: clamp(.8em, .8em + 1vw, 1.2em);
  max-width: 600px;
  margin: 30px;
  text-align: center;
  font-weight: 300;
}
/*=============== DIFERENCIAIS ===============*/
.diferenciais-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--first-color);
  color: white;
}

.nosso-diferencial{
border-bottom: 1px solid rgb(255, 255, 255);
}
.diferenciais-container h3{
  text-align: center;
  width: 100vw;
  margin-top: 50px;
  margin-bottom: 50px;
}

.diferencial-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  max-width: 1230px;
  margin: 0 auto;
}

.diferencial-titulo{
font-family: var(--body-font);
font-weight: 600;
font-size: 1.8rem;
}

.diferencial-titulo::before{
  content: "";
  background-color: white;
  color: var(--first-color);
  padding: 8px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 26px;
  margin-right: 10px;
}

.diferencial-titulo--comp::before{
  content: "\f4c6";
}
.diferencial-titulo--met::before{
  content: "\f02d";
}
.diferencial-titulo--prof::before{
  content: "\f19d";
}

.diferencial-texto{
  font-family: var(--body-font);
  font-size: 18px;
  font-weight: 300;
  width: 350px;
}

/*=============== SERVIÇOS ===============*/

.services-container{
display: flex;
flex-direction: column;
align-items: center;
}

.services-h1{
  display: inline-block;
  align-self: center; 
  margin-bottom: 0;
}
.services-container p{
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: center;
  font-family: var(--body-font);
  font-size: max(18px, 1.1vw);
  font-weight: 300;
  width: 60vw;
}


.services-flex{
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow-x: auto;
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto
;
}

.services-flex picture{
  flex-shrink: 0;
  background-color: var(--first-color);
  cursor: pointer;
}


.services-flex h1{
  text-align: center;
  color: white;
  background-color: var(--first-color);
  width: 100%;
  font-size: 1.2em ;
  align-self: center;
}

.services-flex img{
  min-width: 360px;
  min-height: 360px;
  width: 356px;
}


/*=============== GALERIA ===============*/
.galeria{
  background-color: var(--first-color);
  padding-bottom: 50px;
}

.gallery-text {
display: flex;
align-items: center;
justify-content: center; 
flex-direction: column;
}

.gallery-text h1{
  color: white;
  border-bottom: 1px solid white;
}

.gallery-text p{
  margin-bottom: 50px;
  text-align: center;
  font-family: var(--body-font);
  font-size: max(18px, 1.1vw);
  font-weight: 300;
  color: white;
}
/*=============== FOOTER ===============*/
.footer-infos{
  width: 100vw;
  background-color: white; 
  display: flex;
  flex-direction: column;
}

.footer-text{
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
flex-wrap: wrap;
width: 100%;
max-width: 1175px;
text-align: center;
margin-bottom: 50px;
margin-left: auto;
margin-right: auto;
}

.footer-text h2, .footer-text h3{
  font-weight: 300;
  padding: 2px;
  font-family: var(--body-font);
}

.footer-btn{
  margin-top: 20px;
  width: 180px;
  height: 50px;
  color: white;
  background-color: var(--first-color);
  border-radius: 10px;
  border: none;
  margin-left: auto;
  margin-right: auto;
  font-family: var(--body-font);
  cursor: pointer;
}

.footer-btn:hover{
  background-color: lightblue;
  color: black;
}

.footer-btn:active{
  transform: translate(5px, 5px);
}

.localizacao{
  max-width: 100%;
  max-height: 100%;
  width: 1920px;
  height: 400px;
}
.footer-rights{
  background-color: #020202;
  height: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: wrap column;
  margin: 0 auto; 
  text-align: center;
}

.footer-rights--text{
   color: white;
   font-family: var(--body-font);
   font-size: 1.1em;
   font-weight: 600;
}
.fa-copyright{
  color: white;
  margin-right: 5px;
}



/*=============== ZAP ICON ===============*/
.icon-container{
  position: relative;
}

.fixed_icon{
  position: fixed;
  color: white;
  left: 1rem;
  bottom: 1rem;
  font-size: 3rem;
  background-color: forestgreen;
  backdrop-filter: blur(16px);
  display: inline-flex;
  border-radius: .5rem;
  padding: .45rem;
  z-index: 10;
  transition: .4s;
  animation: zap .5s ease-in 0s infinite alternate;
}

.fixed_icon:hover{
  transform: translateY(-.25rem);
}

/*=============== BREAKPOINTS ===============*/

@media (max-width: 840px) {
  /* .banner-container img {
    width: 250px;
  } */
  /* .banner-container {
    background-image: url('../img/banner-mat-2023-mobile.webp');
  } */
  .banner-container h2{
    font-size: 2rem;
  }
  .sobre-text p{
    width: 400px;
  }
  .nosso-diferencial{
    font-size: 2.5rem;
  }

  .diferencial-cards{
  margin-bottom: 50px;
  }
  .diferenciais-container h3{
    margin-bottom: 50px;
  }
  .services-flex::after{
    content: "\f061";
    padding: 8px;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 26px;
    margin-right: 10px;
    color: white;
    background-color: var(--first-color);
    position: absolute;
    right: 250px;
    top: 10px;
    cursor: pointer;
    opacity: 0.5;
    animation: arrow .5s ease-in 0s infinite alternate;
  }

  @keyframes arrow {
    from{
      right: 5.2rem;
    }
    to{
      right: 3.2rem;
    }
  }

}

@keyframes zap {
  from{
    bottom: 3.8rem;
  }
  to{
    bottom: 3.0rem;
  }
}

@media (max-width: 1200px) {
.services-flex{
  display: flex;
  overflow-x: auto;
  width: 100vw;
  justify-content: flex-start;
  align-items: flex-start;
}

.services-flex picture{
  margin-left: 1.4rem;
}
picture:last-child {
  margin-right: 1.4rem;
}
}

@media (max-width: 439px) {

  /* .banner-container img {
    width: 250px;
     
  } */
  .banner-container h1{
    font-size: 2rem;
  }
  .footer-text{
    width: 80vw;
  }

  .sobre-text{
    width: 80vw;
  }

}

@media (max-width: 999px) {
  body {
    overflow-x: hidden;
  }
  .nav-list {
    position: absolute;
    top: 20vh;
    right: 0;
    width: 100vw;
    height: 20vh;
    background:white;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
    transform: translateX(100%);
    transition: transform 0.3s ease-in;
    border: 1px solid rgba(0, 0, 0, 0.279);
    z-index: 3;
  }
  .nav-list li {
    margin: 8px auto;
    opacity: 0;
    border: 1px solid rgba(0, 0, 0, 0.279);
    padding: 4px;
    border-radius: 4px;
  }
  .mobile-menu {
    display: block;
  }

  .form-adress {
    flex-direction: column;
  }
  .form-select {
    flex-direction: column;
  }
  .form-adress > input{
    width:100%;
  }
}

.nav-list.active {
  transform: translateX(0);
}

@keyframes navLinkFade {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/*=============== ZAPFORM ===============*/

 /* body {
  background-color: var(--first-color);
  text-align: center;

} */

input , select , textarea{
  border-radius: 5px;
}


.container {
  margin: auto;
  padding: 12px;
  background-color: var(--first-color);
}

.header-form{
  width: 90%;
  margin: 8px auto;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.header-form > a > img {
  border-radius: 16px;
  padding: 12px;
}

.form-matricula{
  margin: auto;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  border: 1px solid #6666662b;
  border-radius: 8px;
  padding: 24px;
  max-width: 90%;
}

.form-text-input{
  width: 90%;
  padding: 8px;
  margin: auto;
}

.form-adress {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
  margin: 8px auto;
  width: 90%;
}

.form-select {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 90%;
}

.form-select > div > select {
  width: 90%;
  padding: 8px;
  margin: 12px auto;
}

.form-footer {
  border: 1px solid #666;
  border-radius: 8px;
  background-color: #336699;
  padding: 12px;
  margin: auto;
  font-size: clamp(.8em, .8em + 1vw, 1.2em);
  color: #fff;
}

.form-button {
  margin: 8px auto;
  padding: 8px;
  min-width: 250px;
  background-color: rgb(64, 232, 64);
  color: rgb(25, 24, 24);
}

.form-text-area{
  width: 90%;
  padding: 8px;
  margin: auto;
}